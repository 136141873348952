export default class Message {
    public messageType: string;
    public messageData: any;

    constructor(messageType: string, messageData: any) {
        this.messageType = messageType;
        this.messageData = messageData;
    }

    static fromJSON(json: any): Message {
        return new Message(
            json.messageType,
            json.messageData
        );
    }

    toJSON(): any {
        return {
            messageType: this.messageType,
            messageData: this.messageData
        };
    }
}

